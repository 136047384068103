.input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 90%;
}

.input-container-mobile {
  position: relative;
  display: flex;
  align-items: center;
  width: 97%;
}

.styled-input {
  width: 100%;
  padding: 16px 60px 16px 22px; /* Adds space for the button on the right */
  border-radius: 12px;
  border: 1px solid #264653;
  font-size: 16px;
  outline: none;
  box-sizing: border-box;
  background-color: transparent;
}

.styled-input-mobile {
  width: 100%;
  padding: 14px 20px 14px 22px; /* Adds space for the button on the right */
  border-radius: 12px;
  border: 1px solid #264653;
  font-size: 12px;
  outline: none;
  box-sizing: border-box;
  background-color: transparent;
}

.styled-button {
  position: absolute;
  right: 0;
  height: 80%;
  padding: 0 26px;
  margin-right: 6px;
  background-color: #264653;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  border-left: 1px solid #ccc; /* To match the input border */
}

.styled-button-mobile {
  position: absolute;
  right: 0;
  height: 80%;
  padding: 0 26px;
  margin-right: 6px;
  background-color: #264653;
  border: none;
  color: white;
  font-size: 12px;
  cursor: pointer;
  border-radius: 12px;
  border-left: 1px solid #ccc; /* To match the input border */
}

.styled-button:hover {
  background-color: #3e6f82;
}
