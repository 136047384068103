.image-web {
  width: 444px;
  height: 444px;
}

.text-1-mobile {
  font-size: 22px;
}

.container {
  background-size: cover; /* Makes sure the image covers the entire container */
  background-position: center; /* Centers the image */
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column; /* Ensures vertical stacking of children */
  padding-top: 5vh;
  padding-left: 2vw;
}

.container-mobile {
  background-size: cover; /* Makes sure the image covers the entire container */
  background-position: center; /* Centers the image */
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column; /* Ensures vertical stacking of children */
  padding-top: 3vh;
  padding-left: 3vw;
}

.image-container {
  margin-top: 52px; /* Adjust this value as needed to create space between the logo and the image */
  align-items: flex-start;
  display: flex;
}

.horizontal-holder {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
}

.image-mobile-1 {
  margin-top: 4vh;
}

.image-mobile-2 {
  margin-top: 4vh;
  align-self: center;
}
